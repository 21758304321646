//  Foundation for Sites Settings
//  -----------------------------
//
//  Table of Contents:
//
//   1. Global
//   2. Breakpoints
//   3. The Grid
//   4. Base Typography
//   5. Typography Helpers
//   6. Abide
//   7. Accordion
//   8. Accordion Menu
//   9. Badge
//  10. Breadcrumbs
//  11. Button
//  12. Button Group
//  13. Callout
//  14. Close Button
//  15. Drilldown
//  16. Dropdown
//  17. Dropdown Menu
//  18. Flex Video
//  19. Forms
//  20. Label
//  21. Media Object
//  22. Menu
//  23. Off-canvas
//  24. Orbit
//  25. Pagination
//  26. Progress Bar
//  27. Reveal
//  28. Slider
//  29. Switch
//  30. Table
//  31. Tabs
//  32. Thumbnail
//  33. Tooltip
//  34. Top Bar

@import 'util/util';

// 1. Global
// ---------

$global-width: rem-calc(1200);
$global-font-size: 100%;
$global-lineheight: 1.5;
$primary-color: #a65cef;
$secondary-color: #fff;
$success-color: #066a12;
$warning-color: #ffae00;
$alert-color: #ec5840;
$light-gray: #e6e6e6;
$medium-gray: #cacaca;
$dark-gray: #8a8a8a;
$black: #0a0a0a;
$white: #fefefe;
$body-background: $black;
$body-font-color: $white;
$body-font-family: 'Oswald', Helvetica, Roboto, Arial, sans-serif;
$body-antialiased: true;
$text-direction: ltr;
$global-margin: 1rem;
$global-padding: 1rem;
$global-margin: 1rem;
$global-weight-normal: normal;
$global-weight-bold: bold;
$global-radius: 0;
$global-text-direction: ltr;
$rem-base: 16px;

// 2. Breakpoints
// --------------

$breakpoints: (
  small: 0,
  medium: 640px,
  large: 1024px,
  xlarge: 1200px,
  xxlarge: 1440px,
);
$breakpoint-classes: (small medium large);

// 3. The Grid
// -----------

$grid-row-width: $global-width;
$grid-column-count: 12;
$grid-column-gutter: 30px;
$block-grid-max: 6;

// 4. Base Typography
// ------------------

$header-font-family: $body-font-family;
$header-font-weight: $global-weight-normal;
$header-font-style: normal;
$font-family-monospace: Consolas, "Liberation Mono", Courier, monospace;
$header-sizes: (
  small: (
    'h1': 24,
    'h2': 20,
    'h3': 19,
    'h4': 18,
    'h5': 17,
    'h6': 16,
  ),
  medium: (
    'h1': 48,
    'h2': 40,
    'h3': 31,
    'h4': 25,
    'h5': 20,
    'h6': 16,
  ),
);
$header-color: inherit;
$header-lineheight: 1.4;
$header-margin-bottom: 0.5rem;
$header-text-rendering: optimizeLegibility;
$small-font-size: 80%;
$header-small-font-color: $medium-gray;
$paragraph-lineheight: 1.6;
$paragraph-margin-bottom: 1rem;
$paragraph-text-rendering: optimizeLegibility;
$code-color: $black;
$code-font-family: $font-family-monospace;
$code-font-weight: $global-weight-normal;
$code-background: $light-gray;
$code-border: 1px solid $medium-gray;
$code-padding: rem-calc(2 5 1);
$anchor-color: $primary-color;
$anchor-color-hover: scale-color($anchor-color, $lightness: -14%);
$anchor-text-decoration: none;
$anchor-text-decoration-hover: none;
$hr-width: $global-width;
$hr-border: 1px solid $medium-gray;
$hr-margin: rem-calc(20) auto;
$list-lineheight: $paragraph-lineheight;
$list-margin-bottom: $paragraph-margin-bottom;
$list-style-type: disc;
$list-style-position: outside;
$list-side-margin: 1.25rem;
$list-nested-side-margin: 1.25rem;
$defnlist-margin-bottom: 1rem;
$defnlist-term-weight: $global-weight-bold;
$defnlist-term-margin-bottom: 0.3rem;
$blockquote-color: $dark-gray;
$blockquote-padding: rem-calc(9 20 0 19);
$blockquote-border: 1px solid $medium-gray;
$cite-font-size: rem-calc(13);
$cite-color: $dark-gray;
$keystroke-font: $font-family-monospace;
$keystroke-color: $black;
$keystroke-background: $light-gray;
$keystroke-padding: rem-calc(2 4 0);
$keystroke-radius: $global-radius;
$abbr-underline: 1px dotted $black;

// 5. Typography Helpers
// ---------------------

$lead-font-size: $global-font-size * 1.25;
$lead-lineheight: 1.6;
$subheader-lineheight: 1.4;
$subheader-color: $dark-gray;
$subheader-font-weight: $global-weight-normal;
$subheader-margin-top: 0.2rem;
$subheader-margin-bottom: 0.5rem;
$stat-font-size: 2.5rem;

// 6. Abide
// --------

$abide-inputs: true;
$abide-labels: true;
$input-background-invalid: $alert-color;
$form-label-color-invalid: $alert-color;
$input-error-color: $alert-color;
$input-error-font-size: rem-calc(12);
$input-error-font-weight: $global-weight-bold;

// 7. Accordion
// ------------

$accordion-background: $white;
$accordion-plusminus: true;
$accordion-item-color: foreground($accordion-background, $primary-color);
$accordion-item-background-hover: $light-gray;
$accordion-item-padding: 1.25rem 1rem;
$accordion-content-background: $white;
$accordion-content-border: 1px solid $light-gray;
$accordion-content-color: foreground($accordion-background, $primary-color);
$accordion-content-padding: 1rem;

// 8. Accordion Menu
// -----------------

$accordionmenu-arrows: true;

// 9. Badge
// --------

$badge-background: $primary-color;
$badge-color: foreground($badge-background);
$badge-padding: 0.3em;
$badge-minwidth: 2.1em;
$badge-font-size: 0.6rem;

// 10. Breadcrumbs
// ---------------

$breadcrumbs-margin: 0 0 $global-margin 0;
$breadcrumbs-item-font-size: rem-calc(11);
$breadcrumbs-item-color: $primary-color;
$breadcrumbs-item-color-current: $black;
$breadcrumbs-item-color-disabled: $medium-gray;
$breadcrumbs-item-margin: 0.75rem;
$breadcrumbs-item-uppercase: true;
$breadcrumbs-item-slash: true;

// 11. Button
// ----------

$button-padding: 0.85em 1em;
$button-margin: 0 $global-margin $global-margin 0;
$button-fill: solid;
$button-background: $primary-color;
$button-background-hover: scale-color($button-background, $lightness: -15%);
$button-color: #fff;
$button-color-alt: #000;
$button-radius: $global-radius;
$button-sizes: (
  tiny: 0.6rem,
  small: 0.75rem,
  default: 0.9rem,
  large: 1.25rem,
);
$button-opacity-disabled: 0.25;

// 12. Button Group
// ----------------

$buttongroup-margin: 1rem;
$buttongroup-spacing: 1px;
$buttongroup-child-selector: '.button';
$buttongroup-expand-max: 6;

// 13. Callout
// -----------

$callout-background: $white;
$callout-background-fade: 85%;
$callout-border: 1px solid rgba($black, 0.25);
$callout-margin: 0 0 1rem 0;
$callout-padding: 1rem;
$callout-font-color: $body-font-color;
$callout-font-color-alt: $body-background;
$callout-radius: $global-radius;
$callout-link-tint: 30%;

// 14. Close Button
// ----------------

$closebutton-position: right top;
$closebutton-offset-horizontal: 1rem;
$closebutton-offset-vertical: 0.5rem;
$closebutton-size: 2em;
$closebutton-lineheight: 1;
$closebutton-color: $dark-gray;
$closebutton-color-hover: $black;

// 15. Drilldown
// -------------

$drilldown-transition: transform 0.15s linear;
$drilldown-arrows: true;

// 16. Dropdown
// ------------

$dropdown-padding: 1rem;
$dropdown-border: 1px solid $medium-gray;
$dropdown-font-size: 16rem;
$dropdown-width: 300px;
$dropdown-radius: $global-radius;
$dropdown-sizes: (
  tiny: 100px,
  small: 200px,
  large: 400px,
);

// 17. Dropdown Menu
// -----------------

$dropdownmenu-arrows: true;
$dropdownmenu-min-width: 200px;
$dropdownmenu-background: $white;
$dropdown-border: 1px solid $medium-gray;

// 18. Flex Video
// --------------

$flexvideo-padding-top: rem-calc(25);
$flexvideo-margin-bottom: rem-calc(16);
$flexvideo-ratio: 4 by 3;
$flexvideo-ratio-widescreen: 16 by 9;

// 19. Forms
// ---------

$fieldset-border: 1px solid $medium-gray;
$fieldset-padding: rem-calc(20);
$fieldset-margin: rem-calc(18 0);
$legend-padding: rem-calc(0 3);
$form-spacing: rem-calc(16);
$helptext-color: #333;
$helptext-font-size: rem-calc(13);
$helptext-font-style: italic;
$input-prefix-color: $black;
$input-prefix-background: $light-gray;
$input-prefix-border: 1px solid $medium-gray;
$input-prefix-padding: 1rem;
$form-label-color: $black;
$form-label-font-size: rem-calc(14);
$form-label-font-weight: $global-weight-normal;
$form-label-line-height: 1.8;
$select-background: #fafafa;
$select-triangle-color: #333;
$select-radius: $global-radius;
$input-color: $dark-gray;
$input-font-family: inherit;
$input-font-size: rem-calc(16);
$input-background: $white;
$input-background-focus: $white;
$input-background-disabled: $light-gray;
$input-border: 1px solid $medium-gray;
$input-border-focus: 1px solid $dark-gray;
$input-shadow: inset 0 1px 2px rgba($black, 0.1);
$input-shadow-focus: 0 0 5px $medium-gray;
$input-cursor-disabled: default;
$input-transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
$input-number-spinners: true;
$input-radius: $global-radius;

// 20. Label
// ---------

$label-background: $primary-color;
$label-color: foreground($label-background);
$label-font-size: 0.8rem;
$label-padding: 0.33333rem 0.5rem;
$label-radius: $global-radius;

// 21. Media Object
// ----------------

$mediaobject-margin-bottom: $global-margin;
$mediaobject-section-padding: $global-padding;
$mediaobject-image-width-stacked: 100%;

// 22. Menu
// --------

$menu-margin: 0;
$menu-margin-nested: 1rem;
$menu-item-padding: 0.7rem 1rem;
$menu-icon-spacing: 0.25rem;
$menu-expand-max: 6;

// 23. Off-canvas
// --------------

$offcanvas-size: 250px;
$offcanvas-background: $light-gray;
$offcanvas-zindex: -1;
$offcanvas-transition-length: 0.5s;
$offcanvas-transition-timing: ease;
$offcanvas-exit-background: rgba($white, 0.25);
$maincontent-class: 'off-canvas-content';
$maincontent-shadow: 0 0 10px rgba($black, 0.5);

// 24. Orbit
// ---------

$orbit-bullet-background: $medium-gray;
$orbit-bullet-background-active: $dark-gray;
$orbit-bullet-diameter: 1.2rem;
$orbit-bullet-margin: 0.1rem;
$orbit-bullet-margin-top: 0.8rem;
$orbit-bullet-margin-bottom: 0.8rem;
$orbit-caption-background: rgba($black, 0.5);
$orbit-caption-padding: 1rem;
$orbit-control-background-hover: rgba($black, 0.5);
$orbit-control-padding: 1rem;
$orbit-control-zindex: 10;

// 25. Pagination
// --------------

$pagination-font-size: rem-calc(14);
$pagination-margin-bottom: $global-margin;
$pagination-item-color: $black;
$pagination-item-padding: rem-calc(3 10);
$pagination-item-spacing: rem-calc(1);
$pagination-radius: $global-radius;
$pagination-item-background-hover: $light-gray;
$pagination-item-background-current: $primary-color;
$pagination-item-color-current: foreground($pagination-item-background-current);
$pagination-item-color-disabled: $medium-gray;
$pagination-ellipsis-color: $black;
$pagination-mobile-items: false;
$pagination-arrows: true;

// 26. Progress Bar
// ----------------

$progress-height: 1rem;
$progress-background: $medium-gray;
$progress-margin-bottom: $global-margin;
$progress-meter-background: $primary-color;
$progress-radius: $global-radius;

// 27. Reveal
// ----------

$reveal-background: $white;
$reveal-width: 600px;
$reveal-max-width: $global-width;
$reveal-offset: rem-calc(100);
$reveal-padding: $global-padding;
$reveal-border: 1px solid $medium-gray;
$reveal-radius: $global-radius;
$reveal-zindex: 1005;
$reveal-overlay-background: rgba($black, 0.45);

// 28. Slider
// ----------

$slider-height: 0.5rem;
$slider-width-vertical: $slider-height;
$slider-background: $light-gray;
$slider-fill-background: $medium-gray;
$slider-handle-height: 1.4rem;
$slider-handle-width: 1.4rem;
$slider-handle-background: $primary-color;
$slider-opacity-disabled: 0.25;
$slider-radius: $global-radius;
$slider-transition: all 0.2s ease-in-out;

// 29. Switch
// ----------

$switch-background: $medium-gray;
$switch-background-active: $primary-color;
$switch-height: 2rem;
$switch-height-tiny: 1.5rem;
$switch-height-small: 1.75rem;
$switch-height-large: 2.5rem;
$switch-radius: $global-radius;
$switch-margin: $global-margin;
$switch-paddle-background: $white;
$switch-paddle-offset: 0.25rem;
$switch-paddle-radius: $global-radius;
$switch-paddle-transition: all 0.25s ease-out;

// 30. Table
// ---------

$table-background: $black;
$table-color-scale: 5%;
$table-border: 1px solid smart-scale($table-background, $table-color-scale);
$table-padding: rem-calc(8 10 10);
$table-hover-scale: 2%;
$table-row-hover: darken($table-background, $table-hover-scale);
$table-row-stripe-hover: darken($table-background, $table-color-scale + $table-hover-scale);
$table-striped-background: smart-scale($table-background, $table-color-scale);
$table-stripe: even;
$table-head-background: smart-scale($table-background, $table-color-scale / 2);
$table-foot-background: smart-scale($table-background, $table-color-scale);
$table-head-font-color: $primary-color;
$show-header-for-stacked: false;

// 31. Tabs
// --------

$tab-margin: 0;
$tab-background: $white;
$tab-background-active: $light-gray;
$tab-border: $light-gray;
$tab-item-color: foreground($tab-background, $primary-color);
$tab-item-background-hover: $white;
$tab-item-padding: 1.25rem 1.5rem;
$tab-expand-max: 6;
$tab-content-background: $white;
$tab-content-border: $light-gray;
$tab-content-color: foreground($tab-background, $primary-color);
$tab-content-padding: 1rem;

// 32. Thumbnail
// -------------

$thumbnail-border: solid 4px $white;
$thumbnail-margin-bottom: $global-margin;
$thumbnail-shadow: 0 0 0 1px rgba($black, 0.2);
$thumbnail-shadow-hover: 0 0 6px 1px rgba($primary-color, 0.5);
$thumbnail-transition: box-shadow 200ms ease-out;
$thumbnail-radius: $global-radius;

// 33. Tooltip
// -----------

$tooltip-background-color: $black;
$tooltip-color: $white;
$tooltip-padding: 0.75rem;
$tooltip-font-size: $small-font-size;
$tooltip-pip-width: 0.75rem;
$tooltip-pip-height: $tooltip-pip-width * 0.866;
$tooltip-pip-offset: 1.25rem;
$tooltip-radius: $global-radius;

// 34. Top Bar
// -----------

$topbar-padding: 0.5rem;
$topbar-background: #eee;
$topbar-link-color: #fff;
$topbar-input-width: 200px;

.fullwidth.purpleRow {
  background-color: $primary-color;
  width: 100%;
  margin: 0 auto;
  max-width: initial;
  padding: 0;
}
.fullwidth.purpleRowTop {
  background-color: $primary-color;
  width: 100%;
  margin: 0 auto;
  max-width: initial;
  padding: 0;
}
.full {
  width: 100%;
  margin: 0 auto;
  max-width: initial;
  padding: 0;
  display: block;
}
.purpleBG {
  background-color: $primary-color;
  padding: 1rem 0;
}
.header {
  text-transform: uppercase;
}
.black {
  color: $black;
}
.purple {
  color: $primary-color !important;
}
.white {
  color: $white;
}
.top-bar {
	@include breakpoint(medium only) { 
		width: 100%;
		float: left;
		clear:both;
	}
  li {
    text-transform: uppercase;
    margin: .7rem 2rem 0;
	display: inline-block;
	@include breakpoint(small only) { 
		width: 100%;
		margin: 0;
		padding: .5rem 0;
	}
  }
  li:hover {
	  @include breakpoint(small only) { 
		background: #222;
	}
  
  }
  li a {
	  color: #fff;
  }
  ul {
    background:none !important;
	list-style:none !important;
	@include breakpoint(small only) { 
		margin: 0;
	}
  }
  text-align: center;
  background:none !important;
  
}
.footer {
  height: 15px;
  font-size: .8rem;
  color: #000;
  background-color: #a65cef;
  text-align: center;
  padding: 1rem 0 2rem 0;
  margin: 2rem 0 0 0;
}
#hatImg {
  display: block;
  margin: .5rem auto;
}
.icons {
  max-width: 50%;
}
#hats {
  list-style-type: none;
  margin: 0;
  color: #111;
  padding-bottom: .5rem;
}
h4 {
  color: #a3a3a3 !important;
}
.callout.primary {
  background-color: $primary-color !important;
}
.accordion {
  background: none !important;
  border:none !important;
}
.accordion img {
	@include breakpoint(small only) { 
		margin:0 auto;
		padding-bottom:  1rem;
		text-align: center;
		display:block;
	}
}
.accordion-content p a {
	@include breakpoint(small only) { 
		margin:0 auto;
		text-align: center;
		display:block;
	}
}
.accordion-title::before {
  content: '+' !important;
  font-size: 3rem !important;
  position: absolute !important;
  top: 50% !important;
  left: 20%;
  margin-top: -2rem !important;
  margin-left: -3rem !important;
}
.accordion-title {
  display: block !important;
  padding: 1.25rem 1rem 1.25rem 20% !important;
  color: #fff !important;
  text-transform: uppercase;
  position: relative !important;
  border-bottom: 1px solid #e6e6e6;
}
.accordion-title:hover, .accordion-title:focus {
  color: #f1a209 !important;
  background: none !important;
}
.is-active > .accordion-title::before {
  content: '–' !important;
}
@media screen and (min-width: 40em) {
  .reveal {
    width: 80% !important;
    max-width: 75rem; } 
}
.modalImg {
  padding: 0 2rem 1rem 0;
  display:block;
}
.modalImgR {
  padding: 0 0 1rem 1rem;
  display:block;
}
.reveal {
  background-color: #eee !important;
  position:absolute !important;
  top:0px !important;
  overflow:scroll !important;
}
.accordion-title:hover .accOrange, .accordion-title:focus .accOrange, .accordion-title:active .accOrange {
  color: #f1a209 !important;
}
.accordion-title:hover.accRed, .accordion-title:focus.accRed, .accordion-title:active.accRed {
  color: #E80B2C !important;
}
.accordion-title:hover.accTeal, .accordion-title:focus.accTeal, .accordion-title:active.accTeal {
  color: #16C6CC !important;
}
.accordion-title:hover.accYellow, .accordion-title:focus.accYellow, .accordion-title:active.accYellow {
  color: #f4e414 !important;
}
.accordion-title:hover.accGreen, .accordion-title:focus.accGreen, .accordion-title:active.accGreen {
  color: #4dea13 !important;
}
.accordion-title:hover.accBlue, .accordion-title:focus.accBlue, .accordion-title:active.accBlue {
  color: #176ecc !important;
}
.accordion-title:hover.accPink, .accordion-title:focus.accPink, .accordion-title:active.accPink {
  color: #ed43a7 !important;
}
.accordion-title:hover.accOrange2, .accordion-title:focus.accOrange2, .accordion-title:active.accOrange2 {
  color: #ff6700 !important;
}
.accordion-title:hover.accAdven, .accordion-title:focus.accAdven, .accordion-title:active.accAdven {
  color: #39ae00 !important;
}
.accordion-title:hover.accPurple, .accordion-title:focus.accPurple, .accordion-title:active.accPurple {
  color: #8e12ad !important;
}
.listPoints {
	padding-bottom: 1rem;
  list-style-type: disc;
}
.row {
	padding-bottom: 2% !important;
}
.manualsBG {
}
.bxslider {
	margin-left: 0;
}
.sliderMore {
	position:absolute;
	right: 10%; 
	top: 75%;
	display: block;
	
	@include breakpoint(medium down) {
		display: none;
}
}
.clientBtn {
	text-align: center !important;
}
#logo {
	@include breakpoint(medium down) { 
		display: none;
	}
}
#logoMedium {
	@include breakpoint(medium only) { 
		margin: 0 auto;
		display:block;
	}
	@include breakpoint(large up) { 
		display: none;
	}
	@include breakpoint(small only) { 
		width: 50%;
		background: none!important;
	}
}
.mainUL {
	width: 100%;
	
}
.row {
	@include breakpoint(small only) { 
		padding: .7rem;
	}
	@include breakpoint(medium up) {
		padding: 1rem;
	}
}